<footer class="Footer" [ngClass]="{'Footer--home': inHome}">
    <div class="row NoMargin">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="Footer-logo" routerLink="/" role="img" [attr.alt]="'Common.LogoAlt' | translate"></div>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <ul class="FullWidth Footer-menu">
                <li>
                    <a routerLink="/" class="Footer-menuItem" translate>Footer.Navigation.Home</a>
                </li>
                <li>
                    <a routerLink="/about-us" class="Footer-menuItem" translate>Footer.Navigation.AboutUs</a>
                </li>
                <li>
                    <ddp-sign-in-out class="Footer-menuItem"></ddp-sign-in-out>
                </li>
                <li>
                    <a routerLink="/how-it-works" class="Footer-menuItem" translate>Footer.Navigation.Apply</a>
                </li>
                <li>
                    <a class="Footer-menuItem" (click)="scrollToTop()" translate>Footer.Navigation.Back</a>
                </li>
            </ul>
            <div class="FullWidth Footer-contact">
                <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12 NoPadding">
                    <ul>
                        <li translate>Footer.Address.Text</li>
                        <li>
                            <a [href]="emailHref" class="Footer-contactLink">{{email}}</a>
                        </li>
                        <li>
                            <a [href]="phoneHref" class="Footer-contactLink">{{phone}}</a>
                            <span>
                               ({{'Footer.Address.tollFree' | translate}}
                                <a class="Footer-contactLink" href='tel: 855-534-4300'>855-534-4300</a>)
                            </span>
                        </li>
                        <li>
                            <a target="_blank" [href]="facebookUrl"><img class="Footer-contactLogos" lazy-resource
                                    src="/assets/images/facebook.svg" [alt]="'Footer.FacebookAlt' | translate"></a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12 Footer-contact--last">
                    <ul>
                        <li class="Hidden-mobile"><br /></li>
                        <li translate>Footer.Address.Line1</li>
                        <li translate>Footer.Address.Line2</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
