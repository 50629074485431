<div class="PageHeader">
  <div class="PageHeader-background Background--orange">
    <div class="row PageLayout">
      <div class="col-lg-9 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
        <h1 class="PageHeader-title" translate>HowItWorksPage.Title</h1>
      </div>
    </div>
  </div>
</div>
<div class="Wrapper row" routeTransformer>
  <div class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12 Container Container--howItWorks">
    <img src="/assets/images/how-it-works-family.jpg" class="PageContent-image"
      [alt]="'HowItWorksPage.CommonAlt' | translate">
    <h2 class="Title Color--blue Aligned--center TopMargin50" translate>HowItWorksPage.Intro.Question</h2>
    <p class="Paragraph TopMargin25 Accent">
      <span translate>HowItWorksPage.Intro.Answer.Text1</span>
      “<span class="NotALink" translate>HowItWorksPage.Intro.Answer.Text2</span>”
      <span translate>HowItWorksPage.Intro.Answer.Text3</span>
    </p>

    <ng-container *ngFor="let item of 'HowItWorksPage.Items' | translate; let first = first;">
      <hr *ngIf="first" class="HorizontalLine">

      <hr *ngIf="!first" class="HorizontalLine HorizontalLine--howItWorks">

      <div *ngIf="first" class="row">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <a [href]="item.videoUrl" target="_blank">
            <img
              lazy-resource
              class="PageContent-imageStep PageContent-imageStep--no-border"
              [src]="item.image"
              [alt]="'HowItWorksPage.CommonAlt' | translate"
            />
          </a>
        </div>

        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 PageContent-textStep">
          <h2 class="Title Color--blue">{{ item.title }}</h2>
          <p class="Paragraph" [innerHTML]="item.text"></p>
        </div>
      </div>

      <div *ngIf="!first" class="row">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <img lazy-resource [src]="item.image" class="PageContent-imageStep"
            [alt]="'HowItWorksPage.CommonAlt' | translate">
        </div>
        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 PageContent-textStep">
          <h2 class="Title Color--blue">{{item.title}}</h2>
          <p class="Paragraph" [innerHTML]="item.text"></p>
        </div>
      </div>
    </ng-container>

    <hr class="HorizontalLine">
    <h2 class="Title Color--blue TopMargin50" translate>HowItWorksPage.Ending.Start.Text</h2>
    <div class="CenterDiv TopMargin50">
      <a routerLink="/tell-us-your-story" class="Button--Apply" translate>HowItWorksPage.Ending.Start.Button</a>
    </div>
    <h2 class="Title Color--blue TopMargin75" translate>HowItWorksPage.Ending.AnyQuestions.Question</h2>
    <p class="Paragraph">
      <span translate>HowItWorksPage.Ending.AnyQuestions.Text1</span>
      <a routerLink="/faq" class="Link" translate>HowItWorksPage.Ending.AnyQuestions.Text2</a>,
      <span translate>HowItWorksPage.Ending.AnyQuestions.Text3</span>
      <a [href]="emailHref" class="Link">{{email}}</a>
      <span translate>HowItWorksPage.Ending.AnyQuestions.Text4</span>
      <a [href]="phoneHref" class="Link">{{phone}}</a>
        <span>
                ({{'HowItWorksPage.Ending.AnyQuestions.tollFree' | translate}}
            <a class="Link" href='tel: 855-534-4300'>855-534-4300</a>)</span>.
    </p>

  </div>
</div>
