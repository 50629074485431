<div class="Wrapper--home">
  <div class="FullWidth">
    <div class="Landing-imageContainer">
      <div class="Landing-image Landing-imageLGMD" role="img" [attr.aria-label]="'LGMDPage.ImageAlt' | translate">
      </div>
    </div>
  </div>

  <div class="Landing-message row">
    <section class="col-lg-5 col-lg-offset-7 col-md-6 col-md-offset-6 col-sm-6 col-sm-offset-6 col-xs-12">
      <h2 class="Landing-title" [innerHTML]="'LGMDPage.Title' | translate"></h2>
    </section>
  </div>

  <div class="row Margin--large">
    <section class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <h2 class="Landing-title Color--blue" translate>LGMDPage.Background.Title</h2>
      <p class="Landing-text" translate>LGMDPage.Background.Text</p>
    </section>
  </div>

  <div class="row Margin--large">
    <section class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <h2 class="Landing-title Color--blue" translate>LGMDPage.Genetics.Title</h2>
      <p class="Landing-text" translate>LGMDPage.Genetics.Text</p>
    </section>
  </div>

  <div class="row Landing-article Background--lightBlue">
    <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <h2 class="Landing-title" translate>LGMDPage.Right.Title</h2>
      <p class="Landing-text Aligned--left" translate>LGMDPage.Right.Text</p>
      <ul class="Landing-list Color--white">
        <ng-container *ngFor="let item of 'LGMDPage.Right.Items' | translate">
          <li>{{item}}</li>
        </ng-container>
      </ul>
      <p class="Landing-clarification">
        <span translate>LGMDPage.Right.Ending.Text1</span>
        <a routerLink="/how-it-works" class="Link Link--blue" translate>LGMDPage.Right.Ending.Text2</a>.
      </p>
    </div>
  </div>

  <div class="row Margin--large">
    <section class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
      <h1 class="Landing-title Color--black" translate>LGMDPage.Steps.Title</h1>
      <div class="row Margin--large">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 Landing-step">
          <div class="Landing-stepImage Landing-stepImage01" role="img"
            [attr.alt]="'LGMDPage.Steps.One.Alt' | translate"></div>
          <h3 class="Landing-stepTitle" translate>LGMDPage.Steps.One.Title</h3>
          <p class="Landing-stepText">
            <span translate>LGMDPage.Steps.One.Text1</span>
            <a routerLink="/how-it-works" class="Link" translate>LGMDPage.Steps.One.Text2</a>
            <span translate>LGMDPage.Steps.One.Text3</span>
          </p>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 Landing-step">
          <div class="Landing-stepImage Landing-stepImage02" role="img"
            [attr.alt]="'LGMDPage.Steps.Two.Alt' | translate">
          </div>
          <h3 class="Landing-stepTitle" translate>LGMDPage.Steps.Two.Title</h3>
          <p class="Landing-stepText" translate>LGMDPage.Steps.Two.Text</p>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 Landing-step">
          <div class="Landing-stepImage Landing-stepImage03" role="img"
            [attr.alt]="'LGMDPage.Steps.Three.Alt' | translate">
          </div>
          <h3 class="Landing-stepTitle" translate>LGMDPage.Steps.Three.Title</h3>
          <p class="Landing-stepText" translate>LGMDPage.Steps.Three.Text</p>
        </div>
      </div>
      <hr class="HorizontalLine">
      <div class="CenterDiv Margin--large">
        <a routerLink="/how-it-works" class="Button--Apply" translate>Common.ApplyButton</a>
      </div>
    </section>
  </div>

  <div class="row Landing-article Background--blue">
    <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <h2 class="Landing-title" translate>LGMDPage.Info.Title</h2>
      <p class="Landing-text" [innerHTML]="'LGMDPage.Info.Text' | translate"></p>
    </div>
  </div>

  <div class="Wrapper--homeBottom">
    <div class="row Margin--large">
      <section class="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
        <h2 class="Landing-title" translate>LGMDPage.Partner.Text</h2>
        <div class="row Margin--small">
          <div
            class="col-lg-4 col-lg-offset-4 col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
            <img lazy-resource src="/assets/images/muscular-dystrophy-association-logo.svg"
              [alt]="'LGMDPage.Partner.Alt' | translate">
          </div>
        </div>
      </section>
    </div>
  </div>
</div>