<div class="FullWidth">
  <div class="AboutUs-imageContainer">
    <div
      class="AboutUs-image"
      role="img"
      [attr.aria-label]="'AboutUsPage.ImageAlt' | translate"
    ></div>
  </div>
</div>

<div class="PageHeader-background Background--blue alt">
  <div class="PageLayout">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h1 class="PageHeader-title">{{ 'AboutUsPage.Title' | translate }}</h1>
      </div>
    </div>
  </div>
</div>

<div class="Wrapper row">
  <div
    class="col-lg-9 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12 Container"
  >
    <!-- Intro -->
    <p class="Paragraph TopMargin75" [innerHtml]="'AboutUsPage.Intro.Paragraph' | translate">
    </p>

    <hr class="HorizontalLine" />

    <!-- Our story -->
    <h2 class="Title Color--blue Aligned--center TopMargin75">
      {{ 'AboutUsPage.OurStory.Title' | translate }}
    </h2>

    <p class="Paragraph TopMargin50">
      {{ 'AboutUsPage.OurStory.Paragraph1' | translate }}
    </p>

    <p class="Paragraph">
      {{ 'AboutUsPage.OurStory.Paragraph2' | translate }}
    </p>

    <hr class="HorizontalLine" />

    <!-- Broad Institute -->
    <h2 class="Title Color--blue Aligned--center TopMargin75">
      {{ 'AboutUsPage.BroadInstitute.Title' | translate }}
    </h2>

    <p class="Paragraph TopMargin50">
      {{ 'AboutUsPage.BroadInstitute.Paragraph' | translate }}
    </p>

    <hr class="HorizontalLine" />

    <!-- Our Team -->

    <h2 class="Title Color--blue Aligned--center TopMargin75">
      {{ 'AboutUsPage.OurTeam.Title' | translate }}
    </h2>

    <!-- Leadership -->

    <h3 class="Subtitle Aligned--center">
      {{ 'AboutUsPage.OurTeam.Leadership.Title' | translate }}
    </h3>

    <div class="row">
      <div
        *ngFor="
          let member of 'AboutUsPage.OurTeam.Leadership.Members' | translate
        "
        class="col-lg-4 col-md-4 col-sm-4 col-xs-12"
      >
        <ng-container
          *ngTemplateOutlet="memberTemplate; context: { member: member }"
        ></ng-container>
      </div>
    </div>

    <hr />

    <!-- Project Management -->

    <h3 class="Subtitle Aligned--center">
      {{ 'AboutUsPage.OurTeam.ProjectManagement.Title' | translate }}
    </h3>

    <div class="row">
      <ng-container
        *ngFor="
          let member of 'AboutUsPage.OurTeam.ProjectManagement.Members'
            | translate;
        "
      >
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
          <ng-container
            *ngTemplateOutlet="memberTemplate; context: { member: member }"
          ></ng-container>
        </div>
      </ng-container>
    </div>

    <hr />

    <!-- Others -->

    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <ng-container
          *ngFor="
            let category of 'AboutUsPage.OurTeam.LeftColumn' | translate;
            let first = first
          "
        >
          <h3 class="Subtitle Aligned--center" [class.mt-40]="!first" [innerHtml]="category.Title | translate"></h3>
          <ng-container *ngFor="let member of category.Members">
            <ng-container
              *ngTemplateOutlet="memberTemplate; context: { member: member }"
            ></ng-container>
          </ng-container>
        </ng-container>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <ng-container
          *ngFor="
            let category of 'AboutUsPage.OurTeam.CenterColumn' | translate;
            let first = first
          "
        >
          <h3 class="Subtitle Aligned--center" [class.mt-40]="!first">
            {{ category.Title }}
          </h3>

          <ng-container *ngFor="let member of category.Members">
            <ng-container
              *ngTemplateOutlet="memberTemplate; context: { member: member }"
            ></ng-container>
          </ng-container>
        </ng-container>
      </div>

      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <ng-container
          *ngFor="
            let category of 'AboutUsPage.OurTeam.RightColumn' | translate;
            let first = first
          "
        >
          <h3 class="Subtitle Aligned--center" [class.mt-40]="!first">
            {{ category.Title }}
          </h3>

          <ng-container *ngFor="let member of category.Members">
            <ng-container
              *ngTemplateOutlet="memberTemplate; context: { member: member }"
            ></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <hr class="HorizontalLine" />

    <p
      class="Paragraph Accent Aligned--center"
      [innerHTML]="'AboutUsPage.ThankYou' | translate"
    ></p>

    <hr class="HorizontalLine" />

    <p
      class="Paragraph Aligned--center"
      [innerHTML]="'AboutUsPage.Outro' | translate"
    ></p>
  </div>
</div>

<ng-template #memberTemplate let-member="member">
  <div class="TeamMember">
    <h4
      class="Subtitle TeamMember-name"
      role="button"
      (click)="openDialog(member)"
    >
      {{ member.name }}
    </h4>
  </div>
</ng-template>
