<div class="tell-us-your-story">
  <div class="PageHeader">
    <div class="PageHeader-background Background--orange">
      <div class="row PageLayout">
        <div
          class="col-lg-9 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12"
        >
          <h1 class="PageHeader-title" translate>CountMeInPage.Title</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="Wrapper row">
    <div
      class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12 Container Container--howItWorks"
    >
      <img
        src="/assets/images/tell-us-your-story.jpg"
        class="PageContent-image"
        [alt]="'CountMeInPage.Title' | translate"
      />
      <h2 class="Title Color--blue Aligned--center TopMargin50" translate>
        CountMeInPage.SectionTitle
      </h2>

      <p
        class="Paragraph TopMargin25"
        [innerHTML]="'CountMeInPage.Text' | translate"
      ></p>

      <app-workflow-start-activity></app-workflow-start-activity>
    </div>
  </div>
</div>
