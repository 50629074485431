<div class="ec">
  <header class="ec__header">
    <div class="ec__header-img" role="img"></div>
  </header>

  <div class="ec__title-container">
    <h1 class="ec__title">{{ 'EligibilityPage.Title' | translate }}</h1>
  </div>
</div>

<div class="Wrapper">
  <div class="row">
    <div class="Container">
      <section class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
        <p class="Paragraph TopMargin50" translate>EligibilityPage.Intro.Text1</p>

        <p class="Paragraph">
          <span translate>EligibilityPage.Intro.Text2.Part1</span>
          <a class="Link" routerLink="/limb-girdle-muscular-dystrophy" translate>EligibilityPage.Intro.Text2.Part2</a>
          <span translate>EligibilityPage.Intro.Text2.Part3</span>
          <a class="Link" routerLink="/craniofacial" translate>EligibilityPage.Intro.Text2.Part4</a>,
          <span translate>EligibilityPage.Intro.Text2.Part5</span>
        </p>
      </section>
    </div>
  </div>

  <div class="row Landing-article Background--lightBlue Color--white">
    <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <p class="Paragraph Aligned--left">
        <span translate>EligibilityPage.Eligible.Intro.Text1</span>
        <span class="Accent" translate>EligibilityPage.Eligible.Intro.Text2</span>
      </p>
      <ul>
        <li class="List-li" translate>EligibilityPage.Eligible.List.Item1</li>
        <li class="List-li" translate>EligibilityPage.Eligible.List.Item2</li>
        <li class="List-li" translate>EligibilityPage.Eligible.List.Item3</li>
        <li class="List-li" translate>EligibilityPage.Eligible.List.Item4</li>
      </ul>

      <p class="Paragraph Aligned--left">
        <span translate>EligibilityPage.Eligible.Ending.Text1.Part1</span>
        <span class="Accent" translate>EligibilityPage.Eligible.Ending.Text1.Part2</span>
        <span translate>EligibilityPage.Eligible.Ending.Text1.Part3</span>
      </p>
      <p class="Paragraph Aligned--left">
        <span translate>EligibilityPage.Eligible.Ending.Text2.Part1</span>
        <a class="Link Link--blue" routerLink="/thank-you" translate>EligibilityPage.Eligible.Ending.Text2.Part2</a>.
        <span translate>EligibilityPage.Eligible.Ending.Text2.Part3</span>
      </p>
    </div>
  </div>

  <div class="row Margin--large">
    <section class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <p class="Paragraph">
        <span translate>EligibilityPage.General.MoreLikely.Text.Part1</span>
        <span class="Italic Accent" translate>EligibilityPage.General.MoreLikely.Text.Part2</span>
        <span translate>EligibilityPage.General.MoreLikely.Text.Part3</span>
      </p>

      <ul>
        <li class="List-li" translate>EligibilityPage.General.MoreLikely.List.Item1</li>
        <li class="List-li" translate>EligibilityPage.General.MoreLikely.List.Item2</li>
        <li class="List-li" translate>EligibilityPage.General.MoreLikely.List.Item3</li>
        <li class="List-li" translate>EligibilityPage.General.MoreLikely.List.Item4</li>
      </ul>

      <p class="Paragraph">
        <span translate>EligibilityPage.General.LessLikely.Text.Part1</span>
        <span class="Italic Accent" translate>EligibilityPage.General.LessLikely.Text.Part2</span>
        <span translate>EligibilityPage.General.LessLikely.Text.Part3</span>
      </p>

      <ul>
        <li class="List-li" translate>EligibilityPage.General.LessLikely.List.Item1</li>
        <li class="List-li" translate>EligibilityPage.General.LessLikely.List.Item2</li>
        <li class="List-li" translate>EligibilityPage.General.LessLikely.List.Item3</li>
        <li class="List-li" translate>EligibilityPage.General.LessLikely.List.Item4</li>
      </ul>

      <p class="Paragraph Margin--25">
        <span translate>EligibilityPage.General.Ending.Text1.Part1</span>
        <a class="Link" routerLink="/how-it-works" translate>EligibilityPage.General.Ending.Text1.Part2</a>.
      </p>

      <p class="Paragraph">
        <span translate>EligibilityPage.General.Ending.Text2.Part1</span>
        <a [href]="emailHref" class="Link">{{email}}</a>
        <span translate>EligibilityPage.General.Ending.Text2.Part2</span>
        <a [href]="phoneHref" class="Link">{{phone}}</a>
          <span>
                ({{'EligibilityPage.General.Ending.Text2.tollFree' | translate}}
              <a class="Link" href='tel: 855-534-4300'>855-534-4300</a>)</span>.
          <span translate>EligibilityPage.General.Ending.Text2.Part3</span>
      </p>
    </section>
  </div>

  <div class="Wrapper--homeBottom"></div>
</div>
