<div class="PageHeader">
  <div class="PageHeader-background Background--orange">
    <div class="row PageLayout">
      <div class="col-lg-9 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
        <h1 class="PageHeader-title" translate>StayInformedPage.Title</h1>
      </div>
    </div>
  </div>
</div>
<div class="Wrapper row">
  <div class="col-lg-9 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12 Container">
    <p class="Paragraph">
      <span translate>StayInformedPage.Text</span>
      <a [href]="emailHref" class="Link">{{email}}</a>.
    </p>
    <div class="row NoMargin">
      <button mat-raised-button color="primary" class="Button Float--right" style="margin-top: 25px"
        (click)="returnHome()" [innerHTML]="'StayInformedPage.Button' | translate"></button>
    </div>
  </div>
</div>