<div class="PageHeader">
  <div class="PageHeader-background Background--orange">
    <div class="row PageLayout">
      <div
        class="col-lg-9 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12"
      >
        <h1 class="PageHeader-title" translate>DashboardPage.Title</h1>
      </div>
    </div>
  </div>
</div>
<div class="Wrapper row">
  <div
    class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12 Dashboard-container Container"
  >
    <div *ngIf="loading; else content" class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>

<ng-template #content>
  <div class="infobox">
    <p [innerHTML]="'DashboardPage.Info.Paragraph1' | translate"></p>

    <p>
      <span [innerHTML]="'DashboardPage.Info.Paragraph2.Intro' | translate"></span>

      <a [routerLink]="'/' + Routes.Faq">{{ 'DashboardPage.Info.Paragraph2.Link' | translate }}</a>

      <span [innerHTML]="'DashboardPage.Info.Paragraph2.Closing' | translate"></span>
    </p>
  </div>

  <app-study-messages [persons]="persons"></app-study-messages>

  <div class="TopMargin50">
    <app-user-activities [activities]="activities"></app-user-activities>
  </div>
</ng-template>
