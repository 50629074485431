<div class="Wrapper--home">
  <div class="FullWidth">
    <div class="Landing-imageContainer">
      <div class="Landing-image Landing-imageCraniofacial" role="img"
        [attr.aria-label]="'CraniofacialPage.ImageAlt' | translate">
      </div>
    </div>
  </div>

  <div class="Landing-message row">
    <section class="col-lg-6 col-lg-offset-6 col-md-6 col-md-offset-6 col-sm-6 col-sm-offset-6 col-xs-12">
      <h2 class="Landing-title Landing-titleCraniofacial" [innerHTML]="'CraniofacialPage.Title' | translate"></h2>
    </section>
  </div>

  <div class="row Margin--large">
    <section class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <h2 class="Landing-title" translate>CraniofacialPage.Text</h2>
    </section>
  </div>

  <div class="row Margin--large">
    <section class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <h2 class="Landing-title Color--blue" translate>CraniofacialPage.Background.Title</h2>
      <p class="Landing-text" [innerHTML]="'CraniofacialPage.Background.Text' | translate"></p>
    </section>
  </div>

  <div class="row Margin--large">
    <section class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <h2 class="Landing-title Color--blue" translate>CraniofacialPage.Genetics.Title</h2>
      <p class="Landing-text" translate>CraniofacialPage.Genetics.Text</p>
    </section>
  </div>

  <div class="row Landing-article Background--lightBlue">
    <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <h2 class="Landing-title" translate>CraniofacialPage.Right.Title</h2>
      <p class="Landing-text Aligned--left" translate>CraniofacialPage.Right.Text</p>
      <ul class="Landing-list Color--white">
        <li [innerHTML]="'CraniofacialPage.Right.ListItems.Item1.Text' | translate"></li>
        <ul>
          <li translate>CraniofacialPage.Right.ListItems.Item1.Subitems.Item1</li>
          <li translate>CraniofacialPage.Right.ListItems.Item1.Subitems.Item2</li>
          <li translate>CraniofacialPage.Right.ListItems.Item1.Subitems.Item3</li>
          <li translate>CraniofacialPage.Right.ListItems.Item1.Subitems.Item4</li>
        </ul>
        <li [innerHTML]="'CraniofacialPage.Right.ListItems.Item2' | translate"></li>
        <li translate>CraniofacialPage.Right.ListItems.Item3</li>
        <li translate>CraniofacialPage.Right.ListItems.Item4</li>
      </ul>
    </div>
  </div>

  <div class="row Margin--large">
    <section class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
      <h1 class="Landing-title Color--black" translate>CraniofacialPage.Steps.Title</h1>
      <div class="row Margin--large">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 Landing-step">
          <div class="Landing-stepImage Landing-stepImage01" role="img"
            [attr.alt]="'CraniofacialPage.Steps.One.Alt' | translate"></div>
          <h3 class="Landing-stepTitle" translate>CraniofacialPage.Steps.One.Title</h3>
          <p class="Landing-stepText">
            <span translate>CraniofacialPage.Steps.One.Text1</span>
            <a routerLink="/how-it-works" class="Link" translate>CraniofacialPage.Steps.One.Text2</a>
            <span translate>CraniofacialPage.Steps.One.Text3</span>
          </p>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 Landing-step">
          <div class="Landing-stepImage Landing-stepImage02" role="img"
            [attr.alt]="'CraniofacialPage.Steps.Two.Alt' | translate">
          </div>
          <h3 class="Landing-stepTitle" translate>CraniofacialPage.Steps.Two.Title</h3>
          <p class="Landing-stepText" translate>CraniofacialPage.Steps.Two.Text</p>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 Landing-step">
          <div class="Landing-stepImage Landing-stepImage03" role="img"
            [attr.alt]="'CraniofacialPage.Steps.Three.Alt' | translate">
          </div>
          <h3 class="Landing-stepTitle" translate>CraniofacialPage.Steps.Three.Title</h3>
          <p class="Landing-stepText" translate>CraniofacialPage.Steps.Three.Text</p>
        </div>
      </div>
      <hr class="HorizontalLine">
      <div class="CenterDiv Margin--large">
        <a routerLink="/how-it-works" class="Button--Apply" translate>Common.ApplyButton</a>
      </div>
    </section>
  </div>

  <div class="Wrapper--homeBottom">
    <div class="row Landing-article Background--blue">
      <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
        <h2 class="Landing-title" translate>CraniofacialPage.Info.Title</h2>
        <p class="Landing-text" [innerHTML]="'CraniofacialPage.Info.Text' | translate"></p>
      </div>
    </div>
  </div>
</div>
