<div class="PageHeader">
  <div class="PageHeader-background Background--orange">
    <div class="row PageLayout">
      <div
        class="col-lg-9 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12"
      >
        <h1 class="PageHeader-title">
          {{ 'EmailVerificationRequiredPage.Header' | translate }}
        </h1>
      </div>
    </div>
  </div>
</div>
<div class="Wrapper row">
  <div
    class="col-lg-9 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12 Container"
  >
    <p class="Paragraph">
      {{ 'EmailVerificationRequiredPage.Text' | translate }}
    </p>
  </div>
</div>
