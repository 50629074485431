<div class="row NoMargin">
  <button mat-icon-button class="close-btn" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
  </button>

  <div
    *ngIf="imageUrl"
    class="col-lg-3 col-md-3 col-sm-4 col-xs-4 Dialog-container"
  >
    <img [src]="imageUrl" class="Dialog-photo" [alt]="name" />
  </div>

  <div class="col-lg-9 col-md-9 col-sm-8 col-xs-8 Dialog-container">
    <div mat-dialog-title class="Dialog-header">
      <h2 mat-dialog-title class="Title Color--blue">{{ name }}</h2>
      <p class="Dialog-headerRole" [innerHTML]="data.position | translate"></p>
    </div>
    <mat-dialog-content>
      <p class="Paragraph" [innerHTML]="data.bio"></p>
    </mat-dialog-content>
  </div>
</div>
