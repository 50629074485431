<div class="Wrapper--home">
  <div class="FullWidth">
    <div class="Home-imageContainer">
      <div class="Home-image" role="img" [attr.aria-label]="'HomePage.Title' | translate">
        <span class="Home-slide slide-1"></span>
        <span class="Home-slide slide-2"></span>
        <span class="Home-slide slide-3"></span>
        <span class="Home-slide slide-1b"></span>
      </div>
    </div>
  </div>

  <div class="row Position--relative">
    <section class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 Home-message">
      <h1 class="Home-title" translate>HomePage.Title</h1>
    </section>
  </div>

  <div class="row Margin--75">
    <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <p class="Home-text" translate>HomePage.Text</p>
    </div>
  </div>

  <div class="row NoMargin">
    <section class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <div class="CenterDiv">
        <img lazy-resource src="/assets/images/rare-people-illustration.svg" class="Home-illustration"
          [alt]="'HomePage.IllustrationAlt' | translate">
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-8">
          <h2 class="Landing-title Color--blue Aligned--left" translate>HomePage.MainPoints.Discovery.Title</h2>
          <p class="Home-text Aligned--left TopMargin10"><i translate>HomePage.MainPoints.Discovery.Subtitle</i></p>
          <p class="Home-text Aligned--left" translate>HomePage.MainPoints.Discovery.Text</p>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <img class="FullWidth" lazy-resource src="/assets/images/homeSlider-aid-discovery.jpg"
            [alt]="'HomePage.MainPoints.Discovery.Alt' | translate">
        </div>
      </div>
    </section>
  </div>

  <div class="row Margin--75">
    <section class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <div class="row NoMargin">
        <div class="col-xs-12 col-sm-6 col-md-4 Hidden-mobile">
          <img class="FullWidth" lazy-resource src="/assets/images/homeSlider-find-answers.jpg"
            [alt]="'HomePage.MainPoints.Answers.Alt' | translate">
        </div>
        <div class="col-xs-12 col-sm-6 col-md-8">
          <h2 class="Landing-title Color--blue Aligned--left" translate>HomePage.MainPoints.Answers.Title</h2>
          <p class="Home-text Aligned--left TopMargin10"><i translate>HomePage.MainPoints.Answers.Subtitle</i></p>
          <p class="Home-text Aligned--left" translate>HomePage.MainPoints.Answers.Text</p>
        </div>

        <div class="col-xs-12 Hidden-desktop">
          <img class="FullWidth" lazy-resource src="/assets/images/homeSlider-find-answers.jpg"
            [alt]="'HomePage.MainPoints.Answers.Alt' | translate">
        </div>
      </div>
    </section>
  </div>

  <div class="row Margin--75">
    <section class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <div class="row NoMargin">
        <div class="col-xs-12 col-sm-6 col-md-8">
          <h2 class="Landing-title Color--blue Aligned--left" translate>HomePage.MainPoints.Involved.Title</h2>
          <p class="Home-text Aligned--left TopMargin10"><i translate>HomePage.MainPoints.Involved.Subtitle</i></p>
          <p class="Home-text Aligned--left" translate>HomePage.MainPoints.Involved.Text</p>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4">
          <img class="FullWidth" lazy-resource src="/assets/images/homeSlider-get-involved.jpg"
            [alt]="'HomePage.MainPoints.Involved.Alt' | translate">
        </div>
      </div>
    </section>
  </div>

  <div class="row Margin--75">
    <section class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
      <div class="row NoMargin">
        <div class="col-xs-12 col-sm-6 col-md-4 Hidden-mobile">
          <img class="FullWidth" lazy-resource src="/assets/images/homeSlider-share-data.jpg"
            [alt]="'HomePage.MainPoints.Data.Alt' | translate">
        </div>
        <div class="col-xs-12 col-sm-6 col-md-8">
          <h2 class="Landing-title Color--blue Aligned--left" translate>HomePage.MainPoints.Data.Title</h2>
          <p class="Home-text Aligned--left TopMargin10"><i translate>HomePage.MainPoints.Data.Subtitle</i></p>
          <p class="Home-text Aligned--left">
            <span translate>HomePage.MainPoints.Data.Text1</span>
            <a routerLink="/privacy-and-your-data" class="Link" translate>HomePage.MainPoints.Data.Text2</a>.
          </p>
        </div>

        <div class="col-xs-12 Hidden-desktop">
          <img class="FullWidth" lazy-resource src="/assets/images/homeSlider-share-data.jpg"
            [alt]="'HomePage.MainPoints.Data.Alt' | translate">
        </div>
      </div>
    </section>
  </div>

  <div class="row NoMargin">
    <img lazy-resource src="/assets/images/dna-strand-illustration.svg" class="Home-dnaIllustration"
      [alt]="'HomePage.DNAAlt' | translate" />
  </div>

  <div class="row NoMargin">
    <section class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
      <h2 class="Home-title" translate>HomePage.Steps.Title</h2>
      <div class="row Margin--75">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 Home-step">
          <div class="Home-stepImage Home-stepImage01" role="img" [attr.alt]="'HomePage.Steps.One.Alt' | translate">
          </div>
          <h3 class="Home-stepTitle" translate>HomePage.Steps.One.Title</h3>
          <p class="Home-stepText">
            <span translate>HomePage.Steps.One.Text1</span>
            <a routerLink="/how-it-works" class="Link" translate>HomePage.Steps.One.Text2</a>
            <span translate>HomePage.Steps.One.Text3</span>
          </p>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 Home-step">
          <div class="Home-stepImage Home-stepImage02" role="img" [attr.alt]="'HomePage.Steps.Two.Alt' | translate">
          </div>
          <h3 class="Home-stepTitle" translate>HomePage.Steps.Two.Title</h3>
          <p class="Home-stepText" translate>HomePage.Steps.Two.Text</p>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 Home-step">
          <div class="Home-stepImage Home-stepImage03" role="img" [attr.alt]="'HomePage.Steps.Three.Alt' | translate">
          </div>
          <h3 class="Home-stepTitle" translate>HomePage.Steps.Three.Title</h3>
          <p class="Home-stepText" translate>HomePage.Steps.Three.Text</p>
        </div>
      </div>
      <hr class="HorizontalLine">
      <div class="CenterDiv Margin--75">
        <a routerLink="/how-it-works" class="Button--Apply" translate>Common.ApplyButton</a>
      </div>
    </section>
  </div>

  <div class="row Home-articleLast Background--lightBlue TopMargin75">
    <div class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12 Home-articleText">
      <h2 class="Home-quoteText">
          <span [innerHTML]="'HomePage.Quote.Text' | translate"></span>
      </h2>
      <p class="Home-quoteSignature" translate>HomePage.Quote.Author</p>
    </div>
  </div>

  <div class="row Margin--75">
    <section class="col-lg-10 col-lg-offset-1 col-md-12 col-sm-12 col-xs-12 PartnersSection">
      <h2 class="Home-title" translate>HomePage.Partners.Title</h2>
      <div class="row Margin--50">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 Aligned--center">
          <a href="https://chanzuckerberg.com/science/programs-resources/rare-as-one/" target="_blank">
            <div class="Home-partners Home-partnerRAO" role="img" [attr.alt]="'HomePage.Partners.RareAsOne' | translate"></div>
          </a>
        </div>
      </div>

      <div class="row Margin--25">
        <div class="col-lg-6 col-md-4 col-sm-5 col-xs-12 Aligned--center">
          <a href="https://rarediseases.org/" target="_blank">
            <div class="Home-partners Home-partnerNOFRDD" role="img"
              [attr.alt]="'HomePage.Partners.NOFRDD' | translate">
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-3 col-xs-12 Aligned--center">
          <a href="http://swanusa.org/" target="_blank">
            <div class="Home-partners Home-partnerSWAN" role="img" [attr.alt]="'HomePage.Partners.SWAN' | translate">
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12 Aligned--center">
          <a href="https://www.rarescience.org/" target="_blank">
            <div class="Home-partners Home-partnerRare" role="img" [attr.alt]="'HomePage.Partners.Rare' | translate">
            </div>
          </a>
        </div>
      </div>

      <div class="row Margin--25">
        <div class="col-lg-5 col-md-4 col-sm-4 col-xs-12 Aligned--center">
          <div class="Home-partners Home-partnerRUNMyDNA" role="img"
            [attr.alt]="'HomePage.Partners.RUNMyDNA' | translate"></div>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 Aligned--center">
          <a href="https://www.samebutdifferentcic.org.uk/" target="_blank">
            <div class="Home-partners Home-partnerSBD" role="img" [attr.alt]="'HomePage.Partners.SBD' | translate">
            </div>
          </a>
        </div>
        <div class="col-lg-5 col-md-4 col-sm-4 col-xs-12 Aligned--center">
          <a href="https://urourhope.org/" target="_blank">
            <div class="Home-partners Home-partnerUROurHope" role="img"
              [attr.alt]="'HomePage.Partners.UROurHope' | translate"></div>
          </a>
        </div>
      </div>

      <div class="row Margin--25">
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 Aligned--center">
          <a href="http://awareofangels.org/" target="_blank">
            <div class="Home-partners Home-partnerAOA" role="img" [attr.alt]="'HomePage.Partners.AOA' | translate">
            </div>
          </a>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 Aligned--center">
          <a href="https://sweetnectarsociety.org/" target="_blank">
            <div class="Home-partners Home-partnerSNS" role="img" [attr.alt]="'HomePage.Partners.SNS' | translate">
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 Aligned--center">
          <a href="https://rareundiagnosed.org/" target="_blank">
            <div class="Home-partners Home-partnerRUN" role="img" [attr.alt]="'HomePage.Partners.RUN' | translate">
            </div>
          </a>
        </div>
      </div>

      <div class="row Margin--25">
        <div class="col-lg-6 col-md-4 col-sm-4 col-xs-12 Aligned--center">
          <a href="https://www.mda.org/" target="_blank">
            <div class="Home-partners Home-partnerMDA" role="img" [attr.alt]="'HomePage.Partners.MDA' | translate">
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12 Aligned--center">
          <a href="https://globalgenes.org/" target="_blank">
            <div class="Home-partners Home-partnerGlobalGenes" role="img"
              [attr.alt]="'HomePage.Partners.GlobalGenes' | translate"></div>
          </a>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12 Aligned--center">
          <a href="https://mygene2.org/MyGene2/" target="_blank">
            <div class="Home-partners Home-partnerMyGene2" role="img"
              [attr.alt]="'HomePage.Partners.MyGene2' | translate"></div>
          </a>
        </div>
      </div>

      <div class="row Margin--25">
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 Aligned--center">
          <a href="https://patientworthy.com/" target="_blank">
            <div class="Home-partners Home-partnerPatientWorthy" role="img"
              [attr.alt]="'HomePage.Partners.PatientWorthy' | translate"></div>
          </a>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 Aligned--center">
          <a href="https://www.thegivingpictureproject.com/" target="_blank">
            <div class="Home-partners Home-partnerTGPP" role="img" [attr.alt]="'HomePage.Partners.TGPP' | translate">
            </div>
          </a>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 Aligned--center">
          <a href="https://curehht.org/" target="_blank">
            <div class="Home-partners Home-partnerCureHHT" role="img" [attr.alt]="'HomePage.Partners.CureHHT' | translate">
            </div>
          </a>
        </div>
      </div>
    </section>
  </div>
</div>

<div class="Wrapper--homeBottom">
  <div class="row Home-articleLast Background--orange">
    <div class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12 Home-articleText">
      <h2 class="Home-title Color--white" translate>HomePage.LastSection.Title</h2>
      <div class="CenterDiv Margin--75">
        <a routerLink="/how-it-works" class="Button--Apply--white" translate>Common.ApplyButton</a>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
          <div class="CenterDiv">
            <p class="Color--white Margin--75" translate>HomePage.LastSection.Text</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<hr class="HorizontalLine_Home">
