<main
  class="main main_activity"
  [ngClass]="{ main_sticky: isLoaded && model && model.subtitle }"
>
  <section *ngIf="!isLoaded" class="section section-spinner">
    <div class="section-spinner__header"></div>

    <div class="section-spinner__container">
      <mat-spinner></mat-spinner>
    </div>
  </section>

  <ng-container *ngIf="isLoaded && model">
    <section class="section">
      <!--
      <ddp-subject-panel></ddp-subject-panel>

      <ddp-admin-action-panel
        [activityReadonly]="isReadonly()"
        (requestActivityEdit)="updateIsAdminEditing($event)"
      >
      </ddp-admin-action-panel>
      -->
    </section>

    <section
      *ngIf="model.subtitle"
      class="section sticky-section"
      [ngClass]="{ 'sticky-section_shadow': isScrolled }"
    >
      <div class="content content_tight">
        <div class="sticky-block" [innerHTML]="model.subtitle"></div>
      </div>
    </section>

    <section *ngIf="model.title" class="section header-section">
      <div class="content content_tight">
        <h1 class="activity-header" [innerHTML]="model.title"></h1>
      </div>
    </section>
  </ng-container>

  <section *ngIf="isLoaded" class="section">
    <div class="content content_tight">
      <ng-container *ngIf="shouldShowReadonlyHint">
        <div class="infobox" [innerHTML]="model.readonlyHint"></div>
      </ng-container>

      <ng-container *ngIf="model && model.introduction">
        <ddp-activity-section
          [section]="model.introduction"
          [readonly]="isReadonly() || dataEntryDisabled"
          [validationRequested]="validationRequested"
          [studyGuid]="studyGuid"
          [activityGuid]="activityGuid"
          (embeddedComponentsValidationStatus)="
            updateEmbeddedComponentValidationStatus(0, $event)
          "
          (componentBusy)="embeddedComponentBusy$[0].next($event)"
        >
        </ddp-activity-section>
      </ng-container>

      <!-- Stepper -->
      <ng-container *ngIf="isEnrollmentActivity">
        <ol class="activity-stepper">
          <ng-container
            *ngFor="
              let section of model.sections;
              let i = index;
              let first = first;
              let last = last
            "
            ><li
              *ngIf="section.visible"
              class="activity-stepper__step-container"
            >
              <button
                class="stepper-btn"
                [class.stepper-btn--first]="first"
                [class.stepper-btn--last]="last"
                [class.stepper-btn--active]="isActive(i)"
                [class.stepper-btn--complete]="isCompleted(i)"
                (click)="jumpStep(i)"
              >
                <span class="stepper-btn__label">{{ i + 1 }}</span>
              </button>
            </li>
          </ng-container>
        </ol>
      </ng-container>

      <ddp-activity-section
        *ngIf="currentSection"
        [section]="currentSection"
        [readonly]="isReadonly() || dataEntryDisabled"
        [validationRequested]="validationRequested"
        [studyGuid]="studyGuid"
        [activityGuid]="activityGuid"
        (embeddedComponentsValidationStatus)="
          updateEmbeddedComponentValidationStatus(1, $event)
        "
        (componentBusy)="embeddedComponentBusy$[1].next($event)"
      >
      </ddp-activity-section>

      <ng-container *ngIf="model">
        <ng-container *ngIf="shouldShowReadonlyHint">
          <div class="infobox" [innerHTML]="model.readonlyHint"></div>
        </ng-container>

        <ng-container *ngIf="model.lastUpdatedText">
          <span class="last-updated">{{ model.lastUpdatedText }} </span>
        </ng-container>

        <div
          *ngIf="isLoaded"
          class="survey-buttons"
          [ngClass]="{
            'activity-buttons_mobile':
              (!isStepped || isLastStep) &&
              isAgree() &&
              isLoaded &&
              !isReadonly()
          }"
        >
          <ng-container *ngIf="isEnrollmentActivity">
            <button
              mat-fab
              class="activity-button"
              [disabled]="
                currentSectionIndex === 0 ||
                dataEntryDisabled ||
                (isPageBusy | async)
              "
              (click)="decrementStep()"
            >
              <mat-icon>chevron_left</mat-icon>
            </button>

            <button
              *ngIf="!isLastStep"
              mat-fab
              class="activity-button"
              [disabled]="dataEntryDisabled || (isPageBusy | async)"
              (click)="incrementStep()"
            >
              <mat-icon>chevron_right</mat-icon>
            </button>

            <ng-container *ngIf="isLastStep">
              <button
                #submitButton
                *ngIf="!isReadonly() && !isAgree()"
                class="button button--primary"
                [disabled]="dataEntryDisabled || (isPageBusy | async)"
                (click)="flush()"
                (mouseenter)="mouseEnterOnSubmit()"
              >
                {{
                  ((isPageBusy | async)
                    ? 'SDK.SavingButton'
                    : 'SDK.SubmitButton'
                  ) | translate
                }}
              </button>

              <button
                *ngIf="isReadonly()"
                class="button button--primary"
                (click)="close()"
              >
                {{ 'SDK.CloseButton' | translate }}
              </button>
            </ng-container>
          </ng-container>
        </div>

        <div *ngIf="displayGlobalError$ | async" class="ErrorMessage ErrorMessage--global">
          <span translate>SDK.ValidateError</span>
        </div>
        <div *ngIf="communicationErrorOccurred" class="ErrorMessage ErrorMessage--global">
          <span translate>SDK.CommunicationError</span>
        </div>

        <!-- Closing section -->
        <div class="section section--closing">
          <ddp-activity-section
            *ngIf="model.closing"
            [section]="model.closing"
            [readonly]="isReadonly() || dataEntryDisabled"
            [validationRequested]="validationRequested"
            [studyGuid]="studyGuid"
            [activityGuid]="activityGuid"
            (embeddedComponentsValidationStatus)="
              updateEmbeddedComponentValidationStatus(2, $event)
            "
            (componentBusy)="embeddedComponentBusy$[2].next($event)"
          >
          </ddp-activity-section>
        </div>
      </ng-container>
    </div>
  </section>
</main>
