<div class="PageHeader">
  <div class="PageHeader-background"></div>
</div>
<div class="Wrapper row">
  <div class="col-lg-9 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12 Container">
    <form role="form" [formGroup]="passwordForm" id="passwordForm" (change)="clearErrorMessage()"
      (submit)="checkPassword()">
      <div class="row NoMargin TopMargin25">
        <mat-form-field class="col-lg-12 col-md-12 col-sm-12 col-xs-12 NoPadding Input TopMargin25">
          <input matInput id="irbPassword" formControlName="password" type="password" name="password" required
            [placeholder]="'PasswordPage.Placeholder' | translate">
          <mat-error translate>PasswordPage.PasswordRequiredError</mat-error>
        </mat-form-field>
      </div>
      <mat-error *ngIf="errorMessage" id="irbError">{{errorMessage}}</mat-error>
      <button mat-raised-button id="checkIrbButton" name='checkPasswordButton' color="primary" type="submit"
        class="Button Float--right" style="margin-top: 25px" [innerHTML]="'PasswordPage.SubmitButton' | translate">
      </button>
    </form>
  </div>
</div>